button {
    outline:none;  
}
button:focus{
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
button:hover {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}